var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',{attrs:{"loading":_vm.loadingTree}},[_c('v-card-title',[_c('h3',{staticClass:"ml-3",staticStyle:{"color":"#e33354"}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.menus"))+" ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 ml-3 mt-4",attrs:{"color":"primary","dark":""},on:{"click":_vm.handleNew}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.$t("FORMS.new"))+" "+_vm._s(_vm.$t("FORMS.menu"))+" ")]):_vm._e(),_c('vuetify-draggable-treeview',{on:{"input":_vm.moveMenuItem},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(
                  typeof item.translations[_vm.appLocale] != 'undefined' &&
                  item.translations[_vm.appLocale]
                )?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","small":""}},[_vm._v("check_circle")]):_vm._e()]}},{key:"label",fn:function(ref){
                var item = ref.item;
return [(item.translations[_vm.appLocale])?_c('span',{on:{"click":function($event){return _vm.handleClickItem(item)}}},[_vm._v(" "+_vm._s(item.translations[_vm.appLocale].label)+" ")]):_vm._e()]}},{key:"append",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleNewSubMenu(item)}}},[_vm._v(" mdi-library-plus ")]),(_vm.permissionCan('delete'))?_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}]),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Valóban törölni szeretné a menüpontot?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("FORMS.cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1),_c('MenuModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"statuses":_vm.statuses},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm}})],1)],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }