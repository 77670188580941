<template>
  <div data-app>
    <v-card :loading="loadingTree">
      <v-card-title>
        <h3 style="color: #e33354" class="ml-3">
          {{ $t("FORMS.menus") }}
        </h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-btn
              v-if="permissionCan('create')"
              color="primary"
              dark
              class="mb-2 ml-3 mt-4"
              v-bind="attrs"
              v-on="on"
              @click="handleNew"
            >
              {{ $t("FORMS.new") }} {{ $t("FORMS.menu") }}
            </v-btn>

            <vuetify-draggable-treeview
              v-model="tree"
              v-on:input="moveMenuItem"
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon
                  v-if="
                    typeof item.translations[appLocale] != 'undefined' &&
                    item.translations[appLocale]
                  "
                  color="green"
                  small
                  class="mr-2"
                  >check_circle</v-icon
                >
              </template>

              <template slot="label" slot-scope="{ item }">
                <span
                  v-if="item.translations[appLocale]"
                  @click="handleClickItem(item)"
                >
                  <!-- {{ $helpers.getTranslated(item.translations).label }}!! -->
                  {{ item.translations[appLocale].label }}
                </span>
              </template>

              <template v-slot:append="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="handleNewSubMenu(item)"
                >
                  mdi-library-plus
                </v-icon>
                <!-- <router-link :to="`/cms/menus/${item.id}`">
                  <v-icon small color="primary" class="mr-2">
                    mdi-pencil
                  </v-icon>
                </router-link> -->
                <v-icon
                  small
                  color="primary"
                  v-if="permissionCan('delete')"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </vuetify-draggable-treeview>

            <!--
        <v-treeview
          v-model="tree"
          :open="initiallyOpen"
          :items="menuCollection"
          activatable
          item-key="id"
          open-on-click
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </template>
          <template slot="label" slot-scope="{ item }">
            <v-row>
              <v-col cols="11" sm="11" md="11">
                {{ getTranslated(item) }}
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="1" sm="1" md="1">
                <v-icon small class="mr-2" @click="handleNewSubMenu(item)">
                  mdi-library-plus
                </v-icon>
                <router-link :to="`/cms/menus/${item.id}`">
                  <v-icon small class="mr-2"> mdi-pencil </v-icon>
                </router-link>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </v-col>
            </v-row>
          </template>
        </v-treeview>
        -->

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Valóban törölni szeretné a menüpontot?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete">{{
                    $t("FORMS.cancel")
                  }}</v-btn>
                  <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <MenuModalForm
          :modalData="modalData"
          :permissions="permissions"
          :statuses="statuses"
          @closeModalForm="handleCloseModalForm"
          @saveModalForm="handleSaveModalForm"
        ></MenuModalForm>
      </v-card-text>
    </v-card>

    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import VuetifyDraggableTreeview from "vuetify-draggable-treeview";
import ApiService from "@/core/services/api.service";
import MenuModalForm, { initialFormData } from "./MenuModalForm";
import i18nService from "@/core/services/i18n.service.js";
import SiteService from "@/core/services/site.service.js";

export const ENDPOINT =
  "pages/admin/" + SiteService.getActiveSiteId() + "/menu/";

export default {
  name: "menus",
  components: { MenuModalForm, VuetifyDraggableTreeview },
  data() {
    return {
      appLocale: i18nService.getUserActiveLanguage(),
      showAll: false,
      search: "",
      permissions: [],

      modalData: {
        dialog: false,
        editedId: null,
        menu_id: null,
      },
      editedItem: Object.assign({}, initialFormData),

      dialogDelete: false,

      tree: [],
      loader: false,
      loadingTree: true,
      //initiallyOpen: ["public"],
    };
  },

  computed: {
    ...mapGetters(["menuCollection", "menuFlatCollection", "statuses"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchMenu", "fetchFlatMenu"]),
    editItem(id) {
      this.modalData.editedId = id;
      this.modalData.dialog = true;
    },
    handleNew() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },
    handleSaveModalForm() {
      this.handleCloseModalForm();
    },
    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchMenu().then(() => {
        this.tree = this.menuCollection;
      });
      this.fetchFlatMenu();
      if (this.$route.name !== "menus") this.$router.push({ name: "menus" });
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchMenu().then(() => {
            this.tree = this.menuCollection;
          });
          this.fetchFlatMenu();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },
    handleNewSubMenu(item) {
      this.modalData.menu_id = item.id;
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },
    closeDelete() {
      // this.editedItem = Object.assign({}, initialFormData);
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/cms/menus/${item.id}`);
      }
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "pages.menu." + action);
    },
    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("menu") > -1
        );
      }
    },
    /*getTranslated(item) {
      if (item.translations[this.appLocale]) {
        return item.translations[this.appLocale].label;
      } else {
        return item.translations[Object.keys(item.translations)[0]].label;
      }
    },*/
    moveMenuItem(tree) {
      this.loader = true;
      ApiService.post(
        ENDPOINT.substring(0, ENDPOINT.length - 1) + "-update-position",
        tree
      )
        .then(() => {
          this.fetchMenu().then(() => {
            this.tree = this.menuCollection;
          });
          this.fetchFlatMenu();
          this.loader = false;
        })
        .catch((error) => {
          console.log("Error!: ", error);
          this.loader = false;
        });
    },
  },

  async mounted() {
    this.fetchMenu().then(() => {
      this.tree = this.menuCollection;
      this.loadingTree = false;
    });
    this.fetchFlatMenu();
    this.setPermissions();
  },
};
</script>
